/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";

import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { axiosClient } from "Utilities/axiosConfig";

import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function AddProductPage() {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [count, setCount] = useState("");
  const [price, setPrice] = useState("");
  const [expire, setExpire] = useState(null);
  const [image, setImage] = useState(null);
  const [category, setCategory] = useState("");

  const fileSelectedHandler = (e) => {
    setImage(e.target.files[0]);
    console.log(e.target.files[0]);
  };

  const [cats, setCats] = useState([]);
  const [error, setError] = useState(false);

  const getCats = async () => {
    const headers = { Accept: "application/json" };
    try {
      const res = await axiosClient.get("v1/categories", headers);

      setCats(res.data);
      setError(false);
      console.log(res);
    } catch (err) {
      console.log(err);
      setError(true);
    }
  };

  const addProduct = async (event) => {
    event.preventDefault();
    console.log("function ran");
    try {
      let payload = {
        name_ar: name,
        stock: count,
        price: price,
        image: image,
        category: category,
      };

      console.log(payload);
      if (event.currentTarget.form.reportValidity()) {
        const headers = {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        };
        const res = await axiosClient.post("/products", payload, {
          headers: headers,
        });
        console.log(res);
        alert("Success!");
      }
    } catch (err) {
      console.log(err.response.data.message);
      alert("Error, please try again");
    }
  };

  useEffect(() => {
    getCats();
  }, []);

  if (!cats) return <h1>Loading...</h1>;

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h3 className={classes.cardTitleWhite}>اضافة منتج</h3>
            </CardHeader>
            <form>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="Name"
                      id="name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => setName(e.target.value),
                        required: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="stock"
                      id="count"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => setCount(e.target.value),
                        type: "number",
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="Price"
                      id="price"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => setPrice(e.target.value),
                        type: "number",
                        required: true,
                      }}
                    />
                  </GridItem>
                  {/* <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="Expire"
                      id="expire"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => setExpire(e.target.value),
                        type: "date",
                      }}
                    />
                  </GridItem> */}

                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="Image"
                      id="image"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => fileSelectedHandler(e),
                        type: "file",
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <InputLabel
                      id="demo-simple-select-helper-label"
                      style={{ textAlign: "left" }}
                    >
                      Category
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={category}
                      onChange={(e) => {
                        setCategory(e.target.value);
                      }}
                      fullWidth={true}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {cats.map((c) => {
                        console.log(c.name_ar);
                        return <MenuItem value={c.id}>{c.name_ar}</MenuItem>;
                      })}
                    </Select>
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button
                  color="primary"
                  onClick={(e) => addProduct(e)}
                  type="submit"
                >
                  Add Product
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
