/*eslint-disable*/
import React, { useState, useEffect } from "react";
// react plugin for creating charts

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";

//import Card from "components/Card/Card.js";
//import CardHeader from "components/Card/CardHeader.js";

//import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-react/views/rtlStyle.js";

//rat imports
import { axiosClient } from "../Utilities/axiosConfig";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import AddProductPage from "./AddProductPage";

// const useStyles = makeStyles(styles);
const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 250,
  },
});

export default function ProductsPage() {
  const classes = useStyles();

  const [products, setProducts] = useState([]);
  const [error, setError] = useState(false);

  const getData = async () => {
    const headers = { Accept: "application/json" };
    try {
      const res = await axiosClient.get("/v1/products", headers);
      console.log(res);
      setProducts(res.data);
      setError(false);
    } catch (err) {
      console.log(err.response.data.message);
      setError(true);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  if (!products && !error) return <h1>Loading</h1>;

  return (
    <div>
      <AddProductPage />
      <h1>Products</h1>
      <GridContainer>
        {products.map((p) => {
          return (
            <GridItem xs={6} sm={6} md={3} key={p.id}>
              <Card className={classes.root}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={
                      p.image
                        ? p.image
                        : "https://t4.ftcdn.net/jpg/04/99/93/31/360_F_499933117_ZAUBfv3P1HEOsZDrnkbNCt4jc3AodArl.jpg"
                    }
                    title="Contemplative Reptile"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {p.name_ar}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="h4"
                    >
                      <strong>{`السعر: ${p.price} `}</strong>
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </GridItem>
          );
        })}
      </GridContainer>
    </div>
  );
}
