import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";

import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { axiosClient } from "Utilities/axiosConfig";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function AddCategoryPage() {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [file, setFile] = useState(null);
  const [description, setDescription] = useState("");

  const fileSelectedHandler = (e) => {
    setFile(e.target.files[0]);
    console.log(e.target.files[0]);
  };

  const addCategory = async (event) => {
    event.preventDefault();
    console.log("function ran");
    try {
      let payload = {
        name_ar: name,
        description: description,
        image: file,
      };

      console.log(payload);
      if (event.currentTarget.form.reportValidity()) {
        const headers = {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        };
        const res = await axiosClient.post("/categories", payload, {
          headers: headers,
        });
        console.log(res);
        alert("Success!");
      }
    } catch (err) {
      console.log(err);
      alert("Error, please try again");
    }
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h3 className={classes.cardTitleWhite}>اضافة قسم</h3>
            </CardHeader>
            <form>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="name"
                      id="name"
                      formControlProps={{
                        fullWidth: true,
                        required: true,
                      }}
                      inputProps={{
                        onChange: (e) => setName(e.target.value),
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <p
                      style={{
                        color: "black",
                        textAlign: "left",
                        fontWeight: "100",
                        fontSize: "20px",
                      }}
                    >
                      File
                    </p>
                    <input
                      type="file"
                      onChange={(e) => fileSelectedHandler(e)}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button
                  color="primary"
                  onClick={(event) => addCategory(event)}
                  type="submit"
                >
                  Add Category
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
