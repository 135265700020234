/*eslint-disable*/
import React, { useState, useEffect } from "react";
// react plugin for creating charts

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";

import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-react/views/rtlStyle.js";

//rat imports
import { axiosClient } from "../Utilities/axiosConfig";
import AddUsersPage from "../Screens/AddUsersPage";

const useStyles = makeStyles(styles);

export default function ViewUsersPage() {
  const classes = useStyles();

  const [users, setUsers] = useState([]);

  const getData = async () => {
    let headers = {
      Accept: "application/json",
    };
    try {
      const res = await axiosClient.get("/v1/users", headers);
      let arr = [];
      res.data.map((user) => {
        arr.push([user.id, user.name_ar, user.phone, user.email]);
      });
      setUsers(arr);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <AddUsersPage />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}></GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h3 className={classes.cardTitleWhite}>المستخدمين</h3>
              {/* <p className={classes.cardCategoryWhite}>
                کارکنان جدید از ۱۵ آبان ۱۳۹۶
              </p> */}
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={["التسلسل", "الاسم", "رقم الهاتف", "الايميل"]}
                tableData={users}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
