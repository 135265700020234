import axios from "axios";

export const axiosClient = axios.create({
  //baseURL: "http://general-purpose-dashboard.test/api",
  baseURL: "https://test.al-shamkh.com/api",
  timeout: 30000,
});

// export const axiosClient = axios.create({
//   baseURL: 'http://alqeitharah.com/api/',
//   timeout: 10000,
// });

axiosClient.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error)
);
