import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-react/views/rtlStyle.js";

// core components
import GridItem from "../components/Grid/GridItem.js";
import GridContainer from "../components/Grid/GridContainer.js";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import { axiosClient } from "Utilities/axiosConfig";
import AddCategoryPage from "./AddCategoryPage.js";
import ErrorComponent from "components/errorComponent.js";

// const styles = {
//   cardCategoryWhite: {
//     color: "rgba(255,255,255,.62)",
//     margin: "0",
//     fontSize: "14px",
//     marginTop: "0",
//     marginBottom: "0",
//   },
//   cardTitleWhite: {
//     color: "#FFFFFF",
//     marginTop: "0px",
//     minHeight: "auto",
//     fontWeight: "300",
//     fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
//     marginBottom: "3px",
//     textDecoration: "none",
//   },
// };

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 250,
  },
});

const CategoryPage = () => {
  const classes = useStyles();

  const [cats, setCats] = useState([]);
  const [error, setError] = useState(false);

  const getData = async () => {
    const headers = { Accept: "application/json" };
    try {
      const res = await axiosClient.get("v1/categories", headers);

      setCats(res.data);
      setError(false);
      console.log(res);
    } catch (err) {
      console.log(err);
      setError(true);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (cats.length < 1 && !error)
    return (
      <>
        <AddCategoryPage />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h1>لا توجد اقسام!</h1>
        </div>
      </>
    );
  if (error)
    return (
      <>
        <AddCategoryPage />
        <ErrorComponent refresh={getData} />
      </>
    );
  return (
    <>
      <AddCategoryPage />
      <div>
        <h1>Categories</h1>
        <GridContainer>
          {/* {cats.map((c) => {
            return (
              <GridItem xs={12} sm={12} md={4} key={c.id}>
                <Card profile>
                  <CardAvatar profile>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        src={
                          c.image
                            ? c.image
                            : "https://t4.ftcdn.net/jpg/04/99/93/31/360_F_499933117_ZAUBfv3P1HEOsZDrnkbNCt4jc3AodArl.jpg"
                        }
                        alt="..."
                      />
                    </a>
                  </CardAvatar>
                  <CardBody profile>
                    <h3 className={classes.cardTitle}>{c.name_ar}</h3>
                  </CardBody>
                </Card>
              </GridItem>
            );
          })} */}
          {cats.map((c) => {
            return (
              <GridItem xs={6} sm={6} md={3} key={c.id}>
                <Card className={classes.root}>
                  <CardActionArea>
                    <CardMedia
                      className={classes.media}
                      image={
                        c.image
                          ? c.image
                          : "https://t4.ftcdn.net/jpg/04/99/93/31/360_F_499933117_ZAUBfv3P1HEOsZDrnkbNCt4jc3AodArl.jpg"
                      }
                      title="Contemplative Reptile"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        {c.name_ar}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="h4"
                      >
                        <strong>{`السعر: ${c.price} `}</strong>
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </GridItem>
            );
          })}
        </GridContainer>
      </div>
    </>
  );
};

export default CategoryPage;
