import React from "react";
import GridContainer from "./Grid/GridContainer";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Button from "./CustomButtons/Button";
import GridItem from "./Grid/GridItem";
const ErrorComponent = ({ refresh }) => {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ErrorOutlineIcon style={{ fontSize: "20vh", color: "gray" }} />
          <h3>An Error has Occured</h3>
          <Button color="info" onClick={(e) => refresh()} type="submit">
            Try Again
          </Button>
        </div>
      </GridItem>
    </GridContainer>
  );
};

export default ErrorComponent;
