import React, { useState, useEffect, useContext } from "react";
import { Redirect, useHistory, Route, useLocation } from "react-router-dom";

export const UserContext = React.createContext({});

export const userContextProvider = () => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);

  function goHome() {
    let history = useHistory();
    history.push("/");
  }

  //   const getHalaConn = async () => {
  //     axios
  //       .get('http://api.shashety.com/v1/check/app')
  //       .then(function (response) {
  //         console.log('context hala conn: ', response.data);
  //         setHalaConn(response.data);
  //       })
  //       .catch(function (error) {
  //         setHalaConn(1);
  //         console.log('context connection error', error);
  //       });
  //   };

  const handleLogin = (user, token) => {
    setUser(user);
    setToken(token);
    goHome();
  };

  const handleLogOut = () => {
    setUser(null);
    setToken(null);
  };

  const authenticated = () => {
    console.log("condition met");
    const loggedUser = JSON.parse(localStorage.getItem("user"));
    const loggedToken = localStorage.getItem("token");
    if (loggedUser && loggedToken) {
      handleLogin(loggedUser, loggedToken);
      console.log("condition met");
    }
  };

  return { token, user, loggedIn, handleLogin, handleLogOut, authenticated };
};

export function PrivateRoute({ children, ...rest }) {
  let auth = useContext(UserContext);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
